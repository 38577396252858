<template>
  <scroll-tab :tabs="[]" @scrollToTab="scrollToTab" />
  <div class="pt-24">
    <guide-management @methodChange="(v) => (method = v)" />
    <menu-list ref="menuListRef" :method="method" class="pb-[calc(100dvh-50rem)] mt-[3rem]" />
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

import { definePageMeta } from '@/.nuxt/imports';
import GuideManagement from '@/components/product-home/guide-management.vue';
import MenuList from '@/components/product-home/menu-list.vue';
import ScrollTab from '@/components/product-home/scroll-tab.vue';
import { PRODUCT_FEAT_NAME } from '@/constants/products.const';
import { RELEASE_METHODS } from '@/constants/release-plan.const';

definePageMeta({
  middleware: 'handle-product-data-middleware',
  productFeat: PRODUCT_FEAT_NAME.HOME,
  showProductSearch: true,
  showFeatMenu: false
});

const method = ref(RELEASE_METHODS.OFFICIAL_RELEASE);

const menuListRef = ref<InstanceType<typeof MenuList>>();

const scrollToTab = (groupId: string) => {
  if (menuListRef.value) {
    menuListRef.value.scrollToTab(groupId);
  }
};
</script>
