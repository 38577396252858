<template>
  <s-dialog to="stds-dialog-UTC" size:sm :open="isOpen">
    <s-dialog-overlay />
    <s-dialog-panel class="relative !w-[56rem] rounded-[2.8rem]">
      <st-dialog-header class="pt-20 px-32" @clickClose="handleClose" />
      <s-dialog-content class="px-32 m-0">
        <s-dialog-content-body class="flex flex-col gap-20 items-center !py-0">
          <div ref="loadingRef" class="w-[14rem] h-80"></div>
          <div class="w-full flex flex-col gap-8 items-center justify-center py-8">
            <safe-html
              tag="h3"
              class="!font-bold text-on-surface-elevation-1 text-3xl leading-2xl"
              :html="$t('studio.client_exe_in_prog.popup_title')"
            />
            <safe-html
              tag="p"
              class="text-text2 leading-text2 font-text2 flex items-center min-h-[7rem] text-on-surface-elevation-2 text-center"
              :html="$t('studio.client_exe_in_prog.popup_msg')"
            />
          </div>
        </s-dialog-content-body>
      </s-dialog-content>
      <s-dialog-footer class="pt-0">
        <s-button class="!w-264" variant="secondary" @click="downloadLauncher">
          {{ $t('studio.client_download_btn') }}
        </s-button>
      </s-dialog-footer>
    </s-dialog-panel>
  </s-dialog>
  <s-portal-target name="stds-dialog-UTC" />
</template>
<script lang="ts" setup>
import lottie from 'lottie-web';
import { onMounted, onUnmounted, ref, shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import DownloadLauncherDialog from '@/components/build/dialog/download-launcher-dialog.vue';
import SafeHtml from '@/components/common/safe-html.vue';
import StDialogHeader from '@/components/common/st-dialog-header.vue';
import { closeDialog } from '@/composables/useDialog';
import { getConfigs } from '@/utils/common.util';

const { locale } = useI18n();
const isOpen = ref(true);
const loadingRef = ref<HTMLElement | null>(null);
const loadingAnimation = ref<any>(null);
let animation: any = null;

const handleClose = () => {
  isOpen.value = false;
  closeDialog(
    {
      component: shallowRef(DownloadLauncherDialog),
      props: {},
      severity: 'info'
    },
    null
  );
};

const { STOVE_URL } = getConfigs();

const downloadLauncher = () => {
  const downloadUrl = `${STOVE_URL}/${locale.value}/download?timestamp=${new Date().getTime()}`;
  window.open(downloadUrl, '_blank', 'noopener,noreferrer');
};

onMounted(async () => {
  // Animation Loading
  try {
    loadingAnimation.value = await import('@/assets/images/common/loading-stove.json');

    if (loadingRef.value && loadingAnimation.value) {
      animation = lottie.loadAnimation({
        container: loadingRef.value,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: loadingAnimation.value.default
      });
    }
  } catch (error) {
    // console.error('Failed to load animation:', error);
  }
});

onUnmounted(() => {
  if (animation) {
    animation.destroy();
  }
});
</script>
