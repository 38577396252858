<template>
  <div class="flex flex-col gap-[6rem]">
    <template v-for="group in productHomeFeats" :key="`g-${group.id}`">
      <div :id="`feat-group-${group.id}`">
        <s-text as="h3" role="text1" class="font-medium text-on-surface-elevation-2">
          {{ $t(group.name) }}
        </s-text>
        <div class="grid grid-cols-4 gap-16 mt-16">
          <template v-for="(item, index) in group.children" :key="`i-${index}`">
            <menu-item
              :title="item.name"
              :description="item.description || ''"
              :icon="item.icon"
              :disabled="(item.url === PRODUCT_DISCOUNT_PAGE_URL && !isReleased && !(isVerifyPass && isPageBuild)) || productStore.isMenuDisabled(item)"
              :isRequired="item.requireInMethods?.includes(method)"
              @click="(event) => onItemClick(item, event)"
            />
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { shallowRef } from 'vue';
import { useI18n } from 'vue-i18n';

import DownloadLauncherDialog from '@/components/build/dialog/download-launcher-dialog.vue';
import MenuItem from '@/components/product-home/menu-item.vue';
import { useApp } from '@/composables/useApp';
import { hasActiveDialog, showConfirm, showDialog } from '@/composables/useDialog';
import useUriHandler from '@/composables/useUriHandler';
import { PRODUCT_DISCOUNT_PAGE_URL, PRODUCT_LAUNCH_SETTING_PAGE_URL } from '@/constants/url.const';
import useProductStore from '@/stores/product.store';
import type { RedirectParams } from '@/types/common.type';
import type { ProductFeatChild } from '@/types/product/product-model.type';
import { handleDownloadLauncher } from '@/utils/build.util';
import { getConfigs, redirectTo } from '@/utils/common.util';

defineProps<{
  method: number,
}>();

const { checkProductPermission, getDocsUrl } = useApp();

const i18n = useI18n();
const { t } = i18n;
const productStore = useProductStore();

const { isReleased, productHomeFeats, isVerifyPass, isPageBuild } = storeToRefs(productStore);

let isHandlingLauncher = false;

const onItemClick = async (item: ProductFeatChild, event?: Event) => {
  if (item.typeMenu === 'launcher') {
    event?.preventDefault();

    if (isHandlingLauncher) { return; }

    isHandlingLauncher = true;
    const { isError } = useUriHandler();

    const { LAUNCHER_URL } = getConfigs();

    if (LAUNCHER_URL) {
      isError.value = false;

      handleDownloadLauncher(LAUNCHER_URL);

      setTimeout(async () => {
        if (isError.value && !hasActiveDialog.value) {
          await showDialog({
            component: shallowRef(DownloadLauncherDialog),
            props: {
              class: 'max-w-300'
            }
          });
        }

        isHandlingLauncher = false;
      }, 500);
    } else {
      isHandlingLauncher = false;
    }

    isHandlingLauncher = false;
    return;
  }

  if (!item.internal) {
    if (productStore.isMenuDisabled(item)) {
      const result = await showConfirm({
        content: t('studio.prj_prod.this_prod.home_in_app_pur_cond_type_pc_set_n'),
        confirmLabel: t('studio.prj_prod.this_prod.home_in_app_pur_cond_type_pc_set_n_to_go'),
        cancelLabel: t('studio.common.popup_case_close_btn'),
        cancelVariant: 'outline'
      });
      if (result) {
        return await redirectTo(PRODUCT_LAUNCH_SETTING_PAGE_URL);
      }
      return;
    }
    const isWritable = await checkProductPermission({
      roleId: item.roleAuthorityId,
      checkType: 'read'
    });
    if (!isWritable) {
      return;
    }

    if (item.typeMenu === 'developer-center') {
      const url = getDocsUrl();
      window.open(url, '_blank');
      return;
    }
  }

  const url = productStore.productFeatUrlGenerator(item);
  const redirectParams: RedirectParams = {
    external: item.internal === false,
    withLocale: item.internal
  };
  if (item.target === '_blank') {
    redirectParams.open = {
      target: '_blank'
    };
  }
  redirectTo(url, redirectParams);
};

const scrollToTab = (groupId: string) => {
  const element: HTMLElement | null = document.getElementById(`feat-group-${groupId}`);
  if (!element) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    return;
  }
  window.scrollTo({
    top: element.offsetTop - 30,
    behavior: 'smooth'
  });
};

defineExpose({
  scrollToTab
});
</script>
