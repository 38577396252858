<template>
  <div class="flex gap-12 items-center justify-between">
    <div class="flex flex-col gap-4">
      <s-text as="h2" role="title5" class="font-medium text-on-surface-elevation-2">
        {{ title }}
      </s-text>
      <s-text as="p" role="text4" class="text-on-surface-elevation-3">
        <slot></slot>
      </s-text>
    </div>
    <div class="flex gap-12 shrink-0">
      <button
        class="flex gap-8 items-center bg-neutral-variant-1 rounded-xl text-white px-24 py-16"
        @click="onClick(PRODUCT_RELEASE_PLAN_PAGE_URL)"
      >
        <s-icon icon="ic-v2-community-calendar-line" size="3xl" />
        <s-text as="span" role="btn1" class="font-medium ">
          {{ $t('studio.release_status.view_btn') }}
        </s-text>
      </button>
      <s-button
        v-if="showPrepareReleaseBtn"
        class="!min-w-120"
        size="lg"
        variant="primary"
        :isDisabled="false"
        :hasArrow="false"
        @click="onClick(`${PRODUCT_RELEASE_PLAN_DETAIL_PAGE_URL}?releaseType=${releaseType}`)"
      >
        {{ $t('studio.release_status.release_prep_btn') }}
      </s-button>
    </div>
  </div>
</template>
<script setup lang="ts">
import { computed } from 'vue';

import { PRODUCT_LAUNCH_TYPE } from '@/constants/release-plan.const';
import {
  PRODUCT_RELEASE_PLAN_DETAIL_PAGE_URL,
  PRODUCT_RELEASE_PLAN_PAGE_URL
} from '@/constants/url.const';
import { redirectTo } from '@/utils/common.util';

const props = withDefaults(
  defineProps<{
    title?: string;
    decs?: string;
    showPrepareReleaseBtn?: boolean;
    releaseMethod?: number;
  }>(),
  {
    showPrepareReleaseBtn: true,
    releaseMethod: 1,
    decs: '',
    title: ''
  }
);

const releaseType = computed(() => {
  return props.releaseMethod === 1 ? PRODUCT_LAUNCH_TYPE.PAGE_BUILD : PRODUCT_LAUNCH_TYPE.PAGE;
});

const onClick = (to: string) => {
  redirectTo(to);
};
</script>
