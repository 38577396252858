<template>
  <div class="relative flex flex-col rounded-[2.8rem] px-[3rem] py-32 bg-[#E5EAF4] gap-16">
    <s-text as="h2" role="title5" class="!font-bold text-on-surface-elevation-2">
      {{ $t('studio.prod_home.release_option_select_guide') }}
    </s-text>
    <div class="flex gap-16">
      <label
        class="flex-1 rounded-[2.8rem] border-2 border-solid border-border bg-surface-elevation-1 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] px-24 py-20 has-[:checked]:border-brand-primary cursor-pointer"
      >
        <span class="flex justify-between items-center">
          <input v-model="releaseMethod" type="radio" :value="1" class="peer hidden" />
          <s-icon
            icon="ic-v2-object-game-line"
            size="5xl"
            class="text-on-surface-elevation-1 peer-checked:!text-brand-primary"
          />
          <s-icon
            icon="ic-v2-control-check-circle-fill"
            size="5xl"
            class="text-disabled-variant-1 peer-checked:!text-brand-primary"
          />
        </span>
        <p class="flex flex-col gap-4 mt-8">
          <safe-html
            class="stds-text text-title6 leading-title6 !font-bold"
            tag="span"
            :html="$t('studio.prod_home.release_option1_official_rel')"
          />
          <safe-html
            class="stds-text text-text2 leading-text2 font-text2 text-on-surface-elevation-2"
            tag="span"
            :html="$t('studio.prod_home.release_option1_descr')"
          />
        </p>
      </label>
      <label
        class="flex-1 rounded-[2.8rem] border-2 border-solid border-border bg-surface-elevation-1 shadow-[0_.6rem_1.4rem_0_rgba(0,51,150,.07)] px-24 py-20 has-[:checked]:border-brand-primary cursor-pointer"
      >
        <span class="flex justify-between items-center">
          <input v-model="releaseMethod" type="radio" :value="2" class="peer hidden" />
          <s-icon
            icon="ic-v2-navigation-shop-line"
            size="5xl"
            class="text-on-surface-elevation-1 peer-checked:!text-brand-primary"
          />
          <s-icon
            icon="ic-v2-control-check-circle-fill"
            size="5xl"
            class="text-disabled-variant-1 peer-checked:!text-brand-primary"
          />
        </span>
        <p class="flex flex-col gap-4 mt-8">
          <safe-html
            class="stds-text text-title6 leading-title6 !font-bold"
            tag="span"
            :html="$t('studio.prod_home.release_option2_prod_pg_only')"
          />
          <safe-html
            class="stds-text text-text2 leading-text2 font-text2 text-on-surface-elevation-2"
            tag="span"
            :html="$t('studio.prod_home.release_option2_descr')"
          />
        </p>
      </label>
    </div>
  </div>
</template>
<script setup lang="ts">
import { defineEmits, ref, watch } from 'vue';

import SafeHtml from '@/components/common/safe-html.vue';

const releaseMethod = ref(1);
const emit = defineEmits(['methodChange']);

watch(
  releaseMethod,
  (value: any) => {
    emit('methodChange', value);
  },
  { immediate: true }
);
</script>
