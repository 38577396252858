<template>
  <!-- TabScroll.vue -->
  <div class="sticky top-[16.6rem] bg-background-variant-1 z-[20] -mx-12 px-12">
    <ul
      class="flex relative overflow-hidden after:bg-inverse-elevation-2 after:absolute after:left-0 after:right-0 after:bottom-0 after:w-full after:h-[0.1rem]"
    >
      <li
        v-for="item in groups"
        :key="item.id"
        class="relative basis-full text-md leading-lg font-medium text-on-surface-elevation-1"
      >
        <button
          type="button"
          class="flex h-full w-full items-center justify-center break-all py-8 px-20 min-h-44"
          :class="{ 'after:stds-tab-active-1': item.id === activeId }"
          @click="changeActiveTab(item.id)"
        >
          <span>{{ $t(item.name) }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>
<script setup lang="ts" generic="T extends { id: string, name: string }">
import { storeToRefs } from 'pinia';
import { computed, ref } from 'vue';

import useProductStore from '@/stores/product.store';

const productStore = useProductStore();

const { productHomeFeats } = storeToRefs(productStore);

const groups = computed(() => {
  return [{ id: 'all', name: 'studio.prj_prod.prod_home.tabs_home' }, ...productHomeFeats.value];
});

const $emit = defineEmits(['scrollToTab']);

const activeId = ref('all');
const changeActiveTab = (tabId: string) => {
  activeId.value = tabId;
  $emit('scrollToTab', tabId);
};
</script>
