<template>
  <template v-if="verifyStatus === VERIFY_STATUS.NONE">
    <div class="relative flex flex-col rounded-[2.8rem] px-[3rem] py-32 bg-multiple-layers gap-16">
      <guide-description
        :releaseMethod="releaseMethod"
        :title="$t('studio.prj_prod.this_prod.home_release_status')"
      >
        {{ $t('studio.prj_prod.this_prod.home_release_status_msg1') }}
        <span class="block text-on-surface-elevation-2 font-medium">
          {{ $t(
            'studio.prj_prod.this_prod.home_release_status_msg2'
          ) }}
        </span>
      </guide-description>
    </div>
    <release-selector class="mt-40" @methodChange="methodChange" />
  </template>
  <div v-else class="relative flex flex-col rounded-[2.8rem] px-[3rem] py-32 bg-multiple-layers gap-24">
    <guide-description :showPrepareReleaseBtn="isOnlyProductPage" :title="`${$t('studio.prj_prod.this_prod.home_release_status')}`">
      {{ $t('studio.prj_prod.this_prod.home_release_status_msg1') }}
      <span class="block text-on-surface-elevation-2 font-medium">
        {{ $t('studio.prj_prod.this_prod.home_release_status_msg2') }}
      </span>
    </guide-description>
    <div class="flex gap-12 justify-center">
      <guide-item
        :hasNewNotify="showRedDot(currentPage?.startedAt)"
        :title="$t('studio.prj_prod.this_prod.home_prod_page_title')"
        titleBlue
        class="!bg-neutral-variant-3"
      >
        <s-text
          as="p"
          role="text4"
          class="font-medium text-on-surface-elevation-2 text-ellipsis overflow-hidden break-all line-clamp-2"
        >
          {{ currentPage?.pageName }}
        </s-text>
        <div v-if="waitingPage">
          <will-applied />
          <s-text
            v-if="waitingPage?.pageName"
            as="p"
            role="text4"
            class="font-medium text-on-surface-elevation-2 text-ellipsis overflow-hidden break-all line-clamp-2 mt-8"
          >
            {{ waitingPage?.pageName }}
          </s-text>
          <s-text
            v-if="waitingPage?.startedAt"
            as="p"
            role="cap1"
            class="mt-4 text-on-surface-elevation-3"
          >
            {{ getDateTimeByLocale(waitingPage?.startedAt) }}
          </s-text>
        </div>
      </guide-item>
      <guide-item
        v-if="isWebOrMobile || currentBuild?.buildId"
        :hasNewNotify="showRedDot(currentBuild?.startedAt)"
        :title="$t('studio.prj_prod.this_prod.home_build_title')"
        class="!bg-neutral-variant-3"
        titleBlue
      >
        <s-text as="p" role="text4" class="font-medium text-on-surface-elevation-2">
          <span v-if="currentBuild?.buildName" class="break-all line-clamp-2">
            {{ currentBuild?.buildName }} <br />
          </span>
          <template v-if="isWebOrMobile">
            <safe-html class="text-on-surface-elevation-4" :html="$t('studio.prj_prod.this_prod.home_build_case_web_mobile_msg')" />
            <br />
            <safe-html v-if="directRating && !isUtilityBasicAndDlcType" :html="currentDirectRatingText" />
            <br />
          </template>
          <safe-html v-if="currentBuild?.rating && !isUtilityBasicAndDlcType" :html="currentBuildRatingText" />
        </s-text>
        <div v-if="isRelease && waitingBuild">
          <will-applied />
          <s-text
            v-if="waitingBuild?.buildName"
            as="p"
            role="text4"
            class="font-medium text-on-surface-elevation-2 mt-8"
          >
            {{ waitingBuild?.buildName }}<br />
            <safe-html v-if="waitingBuild?.rating" :html="waitingBuildRatingText" />
          </s-text>
          <s-text
            v-if="waitingBuild?.startedAt"
            as="p"
            role="cap1"
            class="mt-4 text-on-surface-elevation-3"
          >
            {{ getDateTimeByLocale(waitingBuild?.startedAt) }}
          </s-text>
        </div>
      </guide-item>
      <guide-item v-else :title="$t('studio.prj_prod.this_prod.home_build_title')" :titleBlue="isOnlyProductPage">
        <safe-html
          tag="p"
          class="stds-text text-text4 leading-text4 font-text4 text-on-surface-elevation-4"
          :html="$t('studio.prj_prod.this_prod.home_build_msg1') ?? ''"
        />
      </guide-item>
      <guide-item v-if="isOnlyProductPage" :title="$t('studio.prj_prod.this_prod.home_sales_title')" titleBlue>
        <s-text as="p" role="text4" class="text-on-surface-elevation-4">
          {{ $t('studio.prj_prod.this_prod.home_sales_msg') }}
        </s-text>
      </guide-item>
      <guide-item
        v-else
        :hasNewNotify="showRedDot(currentSale?.startedAt)"
        :title="$t('studio.prj_prod.this_prod.home_sales_title')"
        class="!bg-neutral-variant-3"
        titleBlue
      >
        <template v-if="isProductDemoType">
          <div class="flex flex-col gap-4">
            <safe-html
              tag="p"
              class="stds-text text-text4 leading-text4 font-text4 font-medium text-on-surface-elevation-4"
              :html="$t('studio.prj_prod.this_prod.home_sales_title_type_msg')"
            />
          </div>
        </template>
        <template v-else>
          <div v-if="currentSale?.startedAt || currentSale" class="flex flex-col gap-4">
            <s-text v-if="currentSale" as="p" role="text4" class="font-medium text-on-surface-elevation-2">
              {{ currentPriceInfo }}
            </s-text>
            <s-text v-if="currentSale?.startedAt" as="p" role="cap1" class="text-on-surface-elevation-3">
              {{ getDateTimeByLocale(currentSale?.startedAt) }}
            </s-text>
          </div>
          <div v-if="waitingSale && isRelease">
            <will-applied />
            <s-text as="p" role="text4" class="font-medium text-on-surface-elevation-2 mt-8">
              {{ waitingPriceInfo }}
            </s-text>
            <s-text v-if="waitingSale?.startedAt" as="p" role="cap1" class="text-on-surface-elevation-3 mt-4">
              <template v-if="waitingSale?.startedAt">
                {{ getDateTimeByLocale(waitingSale?.startedAt) }}
              </template>
              <template v-if="waitingSale?.endedAt">
                ~ {{ getDateTimeByLocale(waitingSale?.endedAt) }}
              </template>
            </s-text>
          </div>
        </template>
      </guide-item>
      <guide-item
        :hasNewNotify="showRedDot(releasePlan?.recentVerify?.modifiedAt)"
        :title="$t('studio.prj_prod.this_prod.home_review_progress_title')"
        titleBlue
        hasTooltip
        :toolTipContent="$t('studio.prj_prod.this_prod.home_review_progress_tooltip_msg')"
        class="!bg-neutral-variant-3"
      >
        <div class="flex flex-col gap-4">
          <p class="flex gap-4 items-center">
            <span :class="verifyStatusColor" class="w-12 h-12 rounded-full shrink-0"></span>
            <s-text as="span" role="text4" class="font-medium text-on-surface-elevation-2">
              {{ $t(reviewProgressText) }}
            </s-text>
          </p>
          <s-text
            v-if="releasePlan?.recentVerify?.modifiedAt"
            as="p"
            role="cap1"
            class="text-on-surface-elevation-3"
          >
            {{ getDateTimeByLocale(releasePlan?.recentVerify?.modifiedAt) }}
          </s-text>
        </div>
      </guide-item>
      <guide-item
        :hasNewNotify="showRedDot(releasePlan?.release?.startedAt)"
        :title="$t('studio.prj_prod.this_prod.home_release_stt_title')"
        :titleBlue="planStatus === PLAN_STATUS.RELEASE || planStatus === PLAN_STATUS.PRE_PURCHASE || !isPageBuild"
        hasTooltip
        :toolTipContent="$t('studio.prj_prod.this_prod.home_release_stt_tooltip_msg')"
        :class="releaseBgColor"
      >
        <div class="flex flex-row gap-4 items-center">
          <safe-html
            tag="p"
            :class="{
              '!text-on-surface-elevation-2': isRelease || planStatus === PLAN_STATUS.PRE_PURCHASE || isSuspension,
              'text-on-surface-elevation-4': !isSuspension || isPageBuild
            }"
            class="stds-text text-text4 leading-text4 font-text4 font-medium"
            :html="$t(releaseStatusText)"
          />
          <div v-if="restrictStatus !== RESTRICT_STATUS.NONE" class="inline shrink-0 leading-[1]">
            <s-tooltip
              arrow
              :content="`<p class='text-center'>${$t(
                getDisplaySanctionText(restrictStatus, { isGuideManagementPage: true })
              )}</p>`"
              :duration="0"
              :distance="10"
              useFlip
              flipOnUpdate
              placement="top"
              trigger="click"
              :theme="'studio-tooltip'"
              :zIndex="UPPER_DIALOG_Z_INDEX"
              :allowHTML="true"
              :class="'cursor-auto'"
              @show="handleTooltipShow"
              @hide="handleTooltipHide"
            >
              <template #target>
                <s-icon
                  size="xl"
                  icon="ic-v2-state-info-circle-line"
                  class="text-tint-red-a400"
                />
              </template>
            </s-tooltip>
          </div>
        </div>
        <s-text
          v-if="
            currentPage?.startedAt &&
              isVerifyPass &&
              planStatus === PLAN_STATUS.PAGE_OPEN &&
              isOnlyProductPage
          "
          as="p"
          role="cap1"
          class="text-on-surface-elevation-3"
        >
          {{ getDateTimeByLocale(currentPage?.startedAt) }}
        </s-text>
        <s-text
          v-if="releasePlan?.release?.startedAt && !isOnlyProductPage"
          as="p"
          role="cap1"
          class="text-on-surface-elevation-3"
        >
          {{ getDateTimeByLocale(releasePlan?.release?.startedAt) }}
        </s-text>
      </guide-item>
    </div>
  </div>
</template>
<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed, onBeforeUnmount, onUpdated, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import SafeHtml from '@/components/common/safe-html.vue';
import GuideDescription from '@/components/product-home/guide-description.vue';
import GuideItem from '@/components/product-home/guide-item.vue';
import ReleaseSelector from '@/components/product-home/release-selector.vue';
import WillApplied from '@/components/product-home/will-applied.vue';
import { PLATFORM } from '@/constants/common.const';
import { UPPER_DIALOG_Z_INDEX } from '@/constants/layout-css.const';
import {
  PLAN_STATUS,
  PRODUCT_TYPE_DETAIL,
  RESTRICT_STATUS,
  SALE_STATUS,
  VERIFY_STATUS
} from '@/constants/products.const';
import { Confirmation } from '@/enums/common.enum';
import { RatingAge } from '@/enums/rating.enum';
import useProductStore from '@/stores/product.store';
import type { ReleasePlanStatusBuildType } from '@/types/release-plan/release-plan.type';
import { formatPrice } from '@/utils/currency.util';
import { getDateTimeByLocale } from '@/utils/date.util';
import { getDisplaySanctionText } from '@/utils/product.util';

const emit = defineEmits<{(e: 'methodChange', method: number): void }>();

const { t } = useI18n();

const releaseMethod = ref();

const { currentProductDetailType, platform, releasePlan, isPageBuild } = storeToRefs(useProductStore());

const isTooltipVisible = ref(false);

let timeoutId: ReturnType<typeof setTimeout> | null = null;

const methodChange = (method: number) => {
  releaseMethod.value = method;
  emit('methodChange', method);
};

const currentSale = computed(() => {
  return releasePlan?.value?.sale?.current;
});

const waitingSale = computed(() => {
  return releasePlan?.value?.sale?.waiting;
});

const directRating = computed(() => {
  return releasePlan?.value?.build?.directRating;
});

const currentBuild = computed(() => {
  return releasePlan?.value?.build?.current;
});

const waitingBuild = computed(() => {
  return releasePlan?.value?.build?.waiting;
});

const currentPage = computed(() => {
  return releasePlan?.value?.page?.current;
});

const waitingPage = computed(() => {
  return releasePlan?.value?.page?.waiting;
});

const planStatus = computed(() => {
  return releasePlan.value?.release?.planStatus;
});

const displayYn = computed(() => {
  return releasePlan.value?.release?.displayYn ?? Confirmation.YES;
});

const pageDisplayYn = computed(() => {
  return releasePlan.value?.release.pageDisplayYn;
});

const saleStatus = computed(() => {
  return releasePlan.value?.release?.saleStatus;
});

const verifyStatus = computed(() => {
  return releasePlan.value?.recentVerify?.verifyStatus ?? VERIFY_STATUS.NONE;
});

const isVerifyPass = computed(() => {
  return verifyStatus.value === VERIFY_STATUS.PASS;
});

const isOnlyProductPage = computed(() => {
  return !!currentPage.value && !currentBuild.value && !currentSale.value && notPrePurchase.value;
});

const isWebOrMobile = computed(() => {
  return !platform.value.includes(PLATFORM.PC);
});

const notPrePurchase = computed(() => {
  return planStatus.value !== PLAN_STATUS.PRE_PURCHASE;
});

const isUtilityBasicAndDlcType = computed(() => {
  return (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_BASIC ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DLC
  );
});

const isProductDemoType = computed(() => {
  return (
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.UTILITY_DEMO ||
    currentProductDetailType.value === PRODUCT_TYPE_DETAIL.GAME_DEMO
  );
});

const restrictStatus = computed(() => {
  return releasePlan.value?.release?.restrictStatus ?? RESTRICT_STATUS.NONE;
});

const isStopSale = computed(() => saleStatus.value === SALE_STATUS.STOP);

const isNotDisplay = computed(() => displayYn.value === Confirmation.NO);

const getRatingText = (buildType?: ReleasePlanStatusBuildType | null) => {
  if (!buildType?.rating?.length) {
    return '';
  }
  return buildType?.rating
    .map((rating: string) => {
      if (rating === RatingAge.AllAges) {
        return t(
          'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_all'
        );
      }
      return t(
        'studio.prj_prod_mngmt.rating_build_review.self_review.post_review_guide1_rating_by_age',
        { rating }
      );
    })
    .join(' <br />');
};

const currentDirectRatingText = computed(() => getRatingText(directRating.value));

const currentBuildRatingText = computed(() => getRatingText(currentBuild.value));

const waitingBuildRatingText = computed(() => getRatingText(waitingBuild.value));

const isRelease = computed(() => {
  return planStatus.value === PLAN_STATUS.RELEASE && isVerifyPass.value;
});

const verifyStatusColor = computed(() => {
  switch (true) {
    case verifyStatus.value === VERIFY_STATUS.REJECT:
      return 'bg-tint-red-a400';
    case verifyStatus.value === VERIFY_STATUS.START:
      return 'bg-[#FFC226]';
    case isVerifyPass.value:
      return 'bg-success';
    default:
      return 'bg-[#B8CEFF]';
  }
});

const releaseBgColor = computed(() => {
  const statues = [
    PLAN_STATUS.RELEASE,
    PLAN_STATUS.PRE_PURCHASE,
    PLAN_STATUS.PAGE_OPEN,
    PLAN_STATUS.READY
  ] as string[];
  return { '!bg-neutral-variant-3': (planStatus.value && statues.includes(planStatus.value) && isVerifyPass.value) || isSuspension.value };
});

const reviewProgressText = computed(() => {
  switch (true) {
    case verifyStatus.value === VERIFY_STATUS.START:
      return 'studio.prj_prod.this_prod.home_review_progress_stt2';
    case isVerifyPass.value:
      return 'studio.prj_prod.this_prod.home_review_progress_stt3';
    case verifyStatus.value === VERIFY_STATUS.REJECT:
      return 'studio.prj_prod.this_prod.home_review_progress_stt4';
    default:
      return 'studio.prj_prod.this_prod.home_review_progress_stt1';
  }
});

const showRedDot = (datetime?: number) => {
  if (!datetime) {
    return false;
  }
  // check if the datetime is within 24 hours
  const diff = new Date().getTime() - datetime;
  return diff > 0 && diff < 24 * 60 * 60 * 1000;
};

const isSuspension = computed(() => {
  return isStopSale.value || isNotDisplay.value;
});

const releaseStatusText = computed(() => {
  switch (true) {
    case isStopSale.value && isNotDisplay.value:
      return `${t('studio.prj_prod.this_prod.stt_product_page_withdrawn')}<br/>${t(
        'studio.prj_prod.this_prod.stt_release_sale_suspended'
      )}`;
    case isStopSale.value:
      return `${t('studio.prj_prod.this_prod.stt_release_sale_suspended')}`;
    case isNotDisplay.value || (verifyStatus.value === VERIFY_STATUS.REJECT && (planStatus.value === PLAN_STATUS.READY || planStatus.value === PLAN_STATUS.PAGE_OPEN)):
      return 'studio.prj_prod.this_prod.stt_product_page_withdrawn';
    case isVerifyPass.value && planStatus.value === PLAN_STATUS.READY:
      return 'studio.prj_prod.this_prod.stt_product_page_upcoming';
    case isVerifyPass.value && planStatus.value === PLAN_STATUS.PAGE_OPEN:
      return pageDisplayYn.value === Confirmation.NO
        ? 'studio.prj_prod.this_prod.stt_product_page_display_suspended'
        : 'studio.prj_prod.this_prod.stt_product_page_released';
    case isVerifyPass.value && planStatus.value === PLAN_STATUS.PRE_PURCHASE && saleStatus.value === SALE_STATUS.START:
      return 'studio.prj_prod.this_prod.stt_pre_order_started';
    case isVerifyPass.value && planStatus.value === PLAN_STATUS.PRE_PURCHASE && saleStatus.value === SALE_STATUS.END:
      return 'studio.prj_prod.this_prod.stt_pre_order_ended';
    case isVerifyPass.value && planStatus.value === PLAN_STATUS.RELEASE:
      return 'studio.prj_prod.this_prod.stt_official_released';
    default:
      return 'studio.prj_prod.this_prod.home_pre_release_guide';
  }
});

const waitingPriceInfo = computed(() => {
  const {
    defaultPrice = '',
    defaultCurrencyCode = '',
    discountRate = 0,
    defaultDiscountPrice = 0
  } = waitingSale?.value || {};
  let result = `${defaultPrice ?? ''}${defaultCurrencyCode ?? ''}`;

  if (discountRate > 0) {
    result += `(${discountRate}%), ${formatPrice(defaultDiscountPrice ?? defaultPrice, defaultCurrencyCode)}${
      defaultCurrencyCode ?? ''
    }`;
  }
  return result;
});

const currentPriceInfo = computed(() => {
  const { paidYn } = releasePlan?.value?.sale || {};
  const {
    defaultPrice = '',
    defaultCurrencyCode = '',
    discountRate = 0,
    defaultDiscountPrice = 0
  } = currentSale?.value || {};
  let result = `${defaultPrice ?? ''}${defaultCurrencyCode ?? ''}`;
  if (paidYn === Confirmation.NO || releasePlan?.value?.sale?.current?.defaultPrice === 0) {
    return t('studio.group.collection.discount_registration_header_info_free');
  }
  if (discountRate > 0) {
    result += `(${discountRate}%), ${formatPrice(defaultDiscountPrice ?? defaultPrice, defaultCurrencyCode)}${
      defaultCurrencyCode ?? ''
    }`;
  }
  return result;
});

const handleTooltipShow = ($event: { hide: () => void }) => {
  isTooltipVisible.value = true;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }

  timeoutId = setTimeout(() => {
    if (isTooltipVisible.value) {
      $event.hide();
      isTooltipVisible.value = false;
    }
  }, 3000);
};

const handleTooltipHide = () => {
  isTooltipVisible.value = false;
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
};

const updateGuideItemTitlesHeight = () => {
  const guideItems = document.getElementsByClassName('guide-card-title');
  const len = guideItems.length;
  if (len > 0) {
    let maxHeight = 0;
    for (let i = 0; i < len; i++) {
      const item = guideItems[i];
      if (item.clientHeight > maxHeight) {
        maxHeight = item.clientHeight;
      }
    }
    for (let i = 0; i < len; i++) {
      const item = guideItems[i] as HTMLElement;
      item.style.setProperty('height', maxHeight + 'px');
    }
  }
};

onUpdated(() => {
  updateGuideItemTitlesHeight();
});

onBeforeUnmount(() => {
  if (timeoutId) {
    clearTimeout(timeoutId);
  }
});
</script>
